<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Tools.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>please</b> do a psychology question (Create, update, delete)
      </div>
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="custom-v-dropdown"
        no-caret
        right
        no-flip
        text="Actions"
        v-b-tooltip.hover="'Quick actions'"
        ref="myDropdown"
      >
        <template v-slot:button-content>
          <a  class="btn btn-icon" data-toggle="dropdown">
            <span class="svg-icon svg-icon-success svg-icon-2x">
              <inline-svg src="media/svg/icons/Files/File-plus.svg" />
            </span>
          </a>
        </template>
        <!-- Navigation -->
        <div class="navi navi-hover min-w-md-800px">
          <b-dropdown-text tag="div" class="navi-header font-weight-bold">
            Add Data Question:
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
            <a  class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-writing"></i>
              </span>
              <b-form-input
                size="sm"
                placeholder="Enter your number"
                v-model="add_data_question.question_number"
              />
            </a>
          </b-dropdown-text>
          <!-- <b-dropdown-text v-if="selected == 1" tag="div" class="navi-item"> -->
            <b-dropdown-text tag="div" class="navi-item">
            <a  class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-checking"></i>
              </span>
              <b-form-textarea
                id="textarea-small"
                size="sm"
                placeholder="Enter your Question Description  "
                v-model="add_data_question.question_description"
              ></b-form-textarea>
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item">
            <a  class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-list"></i>
              </span>
              <b-form-select  size="sm" v-model="add_data_question.is_example" :options="options"></b-form-select>
            </a>
          </b-dropdown-text>

          <b-dropdown-text v-if="add_data_question.is_example == '1'" tag="div" class="navi-item">
            <a class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-writing"></i>
              </span>
              <b-form-input
                size="sm"
                placeholder="Enter Timer (minutes)"
                v-model="add_data_question.times"
              />
            </a>
          </b-dropdown-text>
          <b-dropdown-text v-else tag="div" class="navi-item">
            <a class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-writing"></i>
              </span>
              <b-form-input
                size="sm"
                placeholder="Enter Group"
                v-model="add_data_question.title_group"
              />
            </a>
          </b-dropdown-text>

            <b-dropdown-text tag="div" class="navi-footer">
              <a @click="saveData" class="btn btn-light-primary font-weight-bolder btn-sm">Save</a>
            </b-dropdown-text>
        </div>
        <!-- End Navigation -->
      </b-dropdown>
    </b-alert>

    <b-alert
        v-for="(question, index) in data_questions"
        :key="index"
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-text">
          <div class="row">
            <h2 v-if="question.IsExample == '1'">Contoh Soal {{ question.QuestionNumber }}</h2> 
            <h2 v-if="question.IsExample == '0'">Soal {{ question.QuestionNumber }}</h2>
            <b-dropdown
                  size="sm"
                  variant="link"
                  toggle-class="custom-v-dropdown"
                  no-caret
                  class="ml-auto"
                  right
                  no-flip
                  v-b-tooltip.hover="'Update'"
                >
                  <template v-slot:button-content>
                    <a  class="btn btn-icon" data-toggle="dropdown">
                      <button @click="updateItem(question)" class="card-title font-weight-bold text-muted text-hover-danger flaticon2 flaticon2-pen"> </button>

                    </a>
                  </template>
                  <!-- Navigation -->
                  <div class="navi navi-hover min-w-md-650px">
                    <b-dropdown-text tag="div" class="navi-header font-weight-bold">
                      Update Data Question:
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <a  class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-writing"></i>
                        </span>
                        <b-form-input
                          size="sm"
                          placeholder="Enter your number"
                          v-model="update_data_question.question_number"
                        />
                      </a>
                    </b-dropdown-text>

                  <b-dropdown-text tag="div" class="navi-item">
                    <a  class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-checking"></i>
                      </span>
                      <b-form-textarea
                        id="textarea-small"
                        size="sm"
                        placeholder="Enter your Question Description  "
                        v-model="update_data_question.question_description"
                      ></b-form-textarea>
                    </a>
                  </b-dropdown-text>

                    <b-dropdown-text tag="div" class="navi-item">
                      <a  class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-list"></i>
                        </span>
                        <b-form-select  size="sm" v-model="update_data_question.is_example" :options="options"></b-form-select>
                      </a>
                    </b-dropdown-text>

                    <b-dropdown-text v-if="update_data_question.is_example == '1'" tag="div" class="navi-item">
                        <a class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-writing"></i>
                          </span>
                          <b-form-input
                            size="sm"
                            placeholder="Enter Timer (minutes)"
                            v-model="update_data_question.times"
                          />
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text v-else tag="div" class="navi-item">
                        <a class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-writing"></i>
                          </span>
                          <b-form-input
                            size="sm"
                            placeholder="Enter Group"
                            v-model="update_data_question.title_group"
                          />
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-footer">
                        <a
                        class="btn btn-light-primary font-weight-bolder btn-sm"  
                        @click="updateData"
                          >Update</a>
                      </b-dropdown-text>
                  </div>
                  <!-- End Navigation -->
                </b-dropdown> 
            <b-dropdown
                    size="sm"
                    variant="link"
                    toggle-class="custom-v-dropdown"
                    no-caret
                    right
                    no-flip
                    v-b-tooltip.hover="'Delete'"
                  >
                    <template v-slot:button-content>
                      <a  class="btn btn-icon" data-toggle="dropdown">
                        <button class="card-title font-weight-bold text-muted text-hover-danger flaticon2 flaticon2-trash">&nbsp; </button>
                      </a>
                    </template>
                    <!-- Navigation -->
                    <div class="navi navi-hover min-w-md-650px">
                      <b-dropdown-text tag="div" class="navi-header font-weight-bold">
                        Delete Data Question:
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>

                      <b-dropdown-text tag="div" class="navi-item">
                        <a  class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-warning"></i>
                          </span>
                          Are you sure you want to delete it?<br>
                        </a>
                      
                          <h2>{{ question.QuestionNumber }}</h2>
                      </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-footer">
                          <button
                          class="btn btn-light-primary font-weight-bolder btn-sm"  @click="deleteItem(question.QuestionID)"
                            >Delete</button
                          >
                        </b-dropdown-text>
                    </div>
                    <!-- End Navigation -->
            </b-dropdown>
          </div>
            <hr>
          <div>
            <b-row  v-if="question.IsExample == '1'" >
              <b-col sm="6">
                <label :id="'label3-'+index"> Waktu (Menit) : {{question.Times}}</label>
              </b-col>
            </b-row>
          <!-- <h4 > Waktu (Menit) : {{question.Times}}</h4> -->
          </div>
          <div>
             <b-row  v-if="question.IsExample != '1'" >
              <b-col sm="6">
                <label :id="'label2-'+index"> Group : {{question.TitleGroup}}</label>
              </b-col>
            </b-row>
          </div>
          <div>
          <!-- <h4 v-html="limitAndBreakText(question.QuestionDescription, 50)"></h4> -->
            <b-row>
                <b-col sm="12">
                  <b-form-textarea
                    disabled
                    :id="'text-area1-'+index"
                    placeholder="Auto height textarea"
                    rows="3"
                    max-rows="8"
                     v-model="question.QuestionDescription"
                  ></b-form-textarea>
                </b-col>
              </b-row>
          </div>
          <div>
             <b-row  v-if="question.IsExample == '1'" >
              <b-col sm="6">
                <label :id="'label1-'+index"> Pilihan Jawaban : (SUKA / TIDAK)</label>
              </b-col>
            </b-row>
          </div>
          <!-- <h4 v-if="question.IsExample == '1'"> Pilihan Jawaban : (SUKA / TIDAK)</h4> -->
          <!-- <div v-if="question.IsExample != '1'">
          <h4 > Group : {{question.TitleGroup}}</h4>
          </div> -->
      </div>
    </b-alert>

    <!-- <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >

    <div class="alert-text">
      <div class="mt-3">
        <h6 class="text-center">Fill alignment</h6>
        <b-pagination v-model="currentPage" :total-rows="rows" align="fill"></b-pagination>
      </div>
    </div>
    </b-alert> -->

   
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Services from "@/core/services/psikologi-api/Services";
import ApiService from "@/core/services/api.service";
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      // selected: null,
       options: [
        //  { value: null, text: 'Please select Question Type' },
         { value: 0, text: 'Question' },
         { value: 1, text: 'Example Question' },
       ],
      data_questions: [],
      checkboxes: [], // Must be an array reference!
       optionsboxes: [
         { text: 'A', value: 'A' },
         { text: 'B', value: 'B' },
         { text: 'C', value: 'C' },
         { text: 'D', value: 'D' },
         { text: 'E', value: 'E' },
         { text: 'F', value: 'F' }
       ],
       optionsboxes_update: [
         { text: 'A', value: 'A' },
         { text: 'B', value: 'B' },
         { text: 'C', value: 'C' },
         { text: 'D', value: 'D' },
         { text: 'E', value: 'E' },
         { text: 'F', value: 'F' }
       ],
       add_data_question: {
            question_id: "",
            question_number: "",
            question_description: "",
            type_test_id: '',
            is_example: 0,
            times: '',
            title_group: '',
          },
        IdQuestion: null,
        update_selected: null, 
        update_checkboxes: null, 
        update_data_question: {
            question_id: "",
            question_number: "",
            question_description: "",
            type_test_id: '',
            is_example: 0,
            times: '',
            title_group: '',
        },
    };
  },
  components: {
  },
  methods: {

    limitAndBreakText(text, limit) {
    const words = text.split(" ");
    let result = "";
    let count = 0;

    for (let i = 0; i < words.length; i++) {
      count += words[i].length;

      if (count <= limit) {
        result += words[i] + " ";
      } else {
        result = result.trim() + "<br>";
        count = 0;
        i--; // Re-process the current word in the next iteration
      }
    }
    return result.trim();
  },

    getMasterQuestions() {
      return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;
        Services.GetId(
          ApiService,
          `master/questions-minat-bakat/masterid`,
          this.$route.params.TypeTestID,
          contentType,
          response => {
            resolve(response.data);

            // Concatenate base URL with QuestionImage
            // const baseUrl = 'https://psikologi.user.cloudjkt02.com/';
            // this.data_questions = response.data.map(question => ({
            //   ...question,
            //   QuestionImage: baseUrl + question.QuestionImage
            // }));
            this.data_questions = response.data
          },
          err => {
            Swal.fire({
              title: "Data Not Found",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: true,
              timer: 1500
            });
            reject(err);
          }
        );
      });
    },


    saveData() {
      const formData = new FormData();
      formData.append("QuestionNumber", this.add_data_question.question_number);
      formData.append("QuestionDescription", this.add_data_question.question_description);
      formData.append("TypeTestID", this.$route.params.TypeTestID);
      formData.append("IsExample", this.add_data_question.is_example);
      formData.append("Times", this.add_data_question.times);
      formData.append("TitleGroup", this.add_data_question.title_group);

      let contentType = `application/form-data`;

      Services.PostData(
        ApiService,
        `master/questions-minat-bakat/`,
        formData,
        contentType,
        // onSuccess function
        response => {
          if (response.status) {
            Swal.fire({
              title: "",
              text: "Data saved successfully.",
              icon: "success",
              heightAuto: true,
              timer: 1500
            });
            // this.loadData();
          } else {
            Swal.fire({
              title: "",
              text: response.data.error,
              icon: "info",
              heightAuto: true,
              timer: 1500
            });
          }

          this.resetForm(this.add_data_question)
             this.loadData();
        },
        // onError function
        error => {
          console.error('Error during API call:', error);
          // Handle unexpected errors
        }
      )
    },

    resetForm(obj){
        obj.question_id = ""
        obj.question_number = ""
        obj.question_description = ""
        obj.type_test_id = ""
        obj.is_example = 0
        obj.times = ""
        obj.title_group = ""
    },

    async updateItem(updatedQustion) {
            // const updatedQustion = this.data_questions.find(item => item.QuestionID === this.data_questions[index].QuestionID);
            this.update_data_question.question_id = updatedQustion.QuestionID
            this.update_data_question.question_number = updatedQustion.QuestionNumber;
            this.update_data_question.question_description = updatedQustion.QuestionDescription;
            this.update_data_question.type_test_id = updatedQustion.TypeTestID;
            this.update_data_question.is_example = updatedQustion.IsExample;
            this.update_data_question.times = updatedQustion.Times;
            this.update_data_question.title_group = updatedQustion.TitleGroup;
          },
    
    updateData() {
          const formData = new FormData();
          formData.append("QuestionNumber", this.update_data_question.question_number);
          formData.append("QuestionDescription", this.update_data_question.question_description);
          formData.append("TypeTestID", this.update_data_question.type_test_id);
          formData.append("IsExample", this.update_data_question.is_example);
          formData.append("TitleGroup", this.update_data_question.title_group);
          formData.append("Times", this.update_data_question.times);

          let contentType = `application/form-data`;

          Services.PutData(
            ApiService,
            `master/questions-minat-bakat/${this.update_data_question.question_id}`,
            formData,
            contentType,
            response => {
              if (response.status) {
                Swal.fire({
                  title: "",
                  text: "Data Update successfully.",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });

                // this.update_data_question.max_answer_opt = '';
                // this.update_data_question.question_number = '';
                // this.update_data_question.question_description = '';
                // this.update_data_question.question_image = '';
                // this.update_selected = null;
                // this.update_data_question.question_id = '';
                // this.checkboxes = [];
                // this.IdType = '';

                     
                    // this.update_data_question.question_id = ""
                    // this.update_data_question.question_number = ""
                    // this.update_data_question.question_description = ""
                    // this.update_data_question.type_test_id = ''
                    // this.update_data_question.is_example = 0
                    // this.update_data_question.times = ''
                    // this.update_data_question.title_group = ''

              } else {
                Swal.fire({
                  title: "",
                  text: response.error || 'Update failed',
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
            },
          )
          .finally(() => {
            this.resetForm(this.update_data_question)
            this.loadData();
            // Hide the dropdown regardless of success or failure
            // this.$nextTick(() => {
            //   if (this.$refs.myDropdown) {
            //     this.$refs.myDropdown.show = false;
            //   }
            // });
          });
      },      

    async deleteItem(index) {
      // this.IdType = index;
      let contentType = `application/form-data`;

      Services.DeleteData(
        ApiService,
        `master/questions-minat-bakat/${index}`,
        contentType,
        response => {
          if (response.status) {
            Swal.fire({
              title: "",
              text: "Data Delete successfully.",
              icon: "success",
              heightAuto: true,
              timer: 1500
            });
            // this.IdType = '';
            this.loadData();
          } else {
            Swal.fire({
              title: "",
              text: response.error || 'Delete failed',
              icon: "info",
              heightAuto: true,
              timer: 1500
            });
          }
        },
      )
      .finally(() => {
        // Hide the dropdown regardless of success or failure
        // this.$nextTick(() => {
        //   if (this.$refs.myDropdown) {
        //     this.$refs.myDropdown.show = false;
        //   }
        // });
      });
    },



    loadData() {
      this.getMasterQuestions()
        .then(() => {
          // Lakukan sesuatu setelah data dimuat
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/master" },
      { title: "Type Test", route: "/typetest" }
    ]);
    this.loadData();
  }
};
</script>

